<!--Apartato de registro manual de asistentes-->
<template>
  <v-row class="mt-12 mx-6">
    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <p class="headline font-weight-bold">
            Configuración del formulario de registro
          </p>
        </v-col>
        <v-col cols="4">
          <v-select
            v-if="tickets.items.length > 0"
            :items="tickets.items"
            v-model="tickets.selected"
            @change="handleTicketSelection"
            item-text="name"
            item-value="id"
            label="Seleccionar Ticket"
            filled
            rounded
          />
        </v-col>
      </v-row>
      <v-divider class="mb-10" />
      <v-row justify="center">
        <v-col
          v-if="tickets.selected && extraFields.length > 0"
          cols="12"
          class="pt-0"
        >
          <v-row
            v-for="(field, index) in extraFields"
            :key="index"
            justify="center"
          >
            <v-col cols="12" class="d-flex justify-end">
              <v-dialog
                v-if="!field.new && !field.edit"
                v-model="field.deleteDialog"
                width="300"
              >
                <template #activator="{ on, attrs }">
                  <icon-action-button
                    tooltip="Eliminar"
                    color="red"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="
                      !user.permissions.Event || !user.permissions.Event.update
                    "
                  >
                    mdi-trash-can-outline
                  </icon-action-button>
                </template>
                <v-card>
                  <v-card-title class="text-h5"
                    >Eliminar campo extra</v-card-title
                  >
                  <v-divider />
                  <v-card-text class="text-justify my-5">
                    {{
                      field.new
                        ? "¿Seguro que no quiere guardar este campo?"
                        : `¿Seguro de que quiere eliminar el campo "${field.name}"?`
                    }}
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="grey"
                      text
                      @click="field.deleteDialog = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="red"
                      text
                      @click="deleteField(field)"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <icon-action-button
                v-if="field.new || field.edit"
                tooltip="Cancelar"
                color="red"
                @click="cancelAction(field)"
              >
                mdi-close
              </icon-action-button>
              <icon-action-button
                v-if="field.new || field.edit"
                :tooltip="
                  (field.new && 'Guardar') || (field.edit && 'Actualizar')
                "
                color="green"
                :disabled="
                  disableField(field) ||
                  !user.permissions.Event ||
                  !user.permissions.Event.update
                "
                @click="saveField(field)"
              >
                mdi-content-save-outline
              </icon-action-button>
              <icon-action-button
                v-if="!field.new && !field.edit"
                tooltip="Editar"
                color="accent"
                :disabled="
                  !user.permissions.Event || !user.permissions.Event.update
                "
                @click="editField(field)"
              >
                mdi-pencil-outline
              </icon-action-button>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <p class="font-weight-bold">Tipo de campo</p>
              <v-radio-group
                :mandatory="false"
                v-model="field.fieldType"
                class="ml-2"
                :disabled="!field.edit && !field.new"
              >
                <v-radio
                  color="primary"
                  label="Campo de texto"
                  value="TEXT"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Opción múltiple"
                  value="LIST"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Archivo (pdf)"
                  value="DOC"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Imagen (jpg, png, jpeg)"
                  value="IMAGE"
                ></v-radio>
                <template v-if="false">
                  <p class="font-weight-medium">Avanzadas</p>
                  <v-radio
                    color="primary"
                    label="Lista de países"
                    value="COUNTRY"
                  ></v-radio>
                </template>
              </v-radio-group>
              <p class="font-weight-bold">Opciones</p>
              <v-switch
                v-model="field.required"
                class="ml-2"
                color="primary"
                label="¿Obligatorio?"
                :disabled="!field.edit && !field.new"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="4">
              <p class="font-weight-bold">Configuración</p>
              <v-text-field
                label="Nombre del campo o pregunta"
                v-model="field.name"
                filled
                rounded
                :disabled="!field.edit && !field.new"
              ></v-text-field>
              <v-text-field
                label="Descripción"
                v-model="field.description"
                filled
                rounded
                :disabled="!field.edit && !field.new"
              ></v-text-field>
              <v-text-field
                label="Orden"
                v-model="field.order"
                filled
                rounded
                :disabled="!field.edit && !field.new"
                :rules="[minValueRule]"
              ></v-text-field>
              <v-combobox
                v-model="field.options"
                v-if="field.fieldType === 'LIST'"
                hide-selected
                hint="Agrega las opciones de tu campo."
                label="Opciones del campo"
                multiple
                persistent-hint
                small-chips
                filled
                rounded
                :disabled="!field.edit && !field.new"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Presiona
                        <kbd>enter</kbd> para crear una nueva opción.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="4">
              <p class="font-weight-bold">Vista previa</p>
              <v-text-field
                v-if="field.fieldType === 'TEXT'"
                :label="field.name || 'Nombre del campo o pregunta'"
                :hint="field.description || 'Ej. Descripción del campo'"
                persistent-hint
                filled
                rounded
              />
              <v-select
                v-if="field.fieldType === 'LIST'"
                :items="field.options"
                :label="field.name || 'Nombre del campo'"
                v-bind:hint="field.description || 'Ej. Descripción del campo'"
                persistent-hint
                filled
                rounded
              />
              <v-file-input
                v-if="field.fieldType == 'DOC'"
                truncate-length="35"
                :hint="field.description || 'Ej. Descripción del campo'"
                persistent-hint
                :label="field.name || 'Nombre del campo'"
                filled
                rounded
              ></v-file-input>
              <v-file-input
                v-if="field.fieldType == 'IMAGE'"
                truncate-length="35"
                :hint="field.description || 'Ej. Descripción del campo'"
                :label="field.name || 'Nombre del campo'"
                persistent-hint
                filled
                rounded
              ></v-file-input>
            </v-col>
            <v-col cols="12" class="my-5">
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
        <h3 v-else-if="!tickets.selected" class="my-10">
          Seleccione un nivel para previsualizar los campos extra
        </h3>
        <h3 v-else class="my-10">
          No se ha creado ningún campo extra para el ticket seleccionado
        </h3>
      </v-row>
      <v-row class="pb-7">
        <v-col
          cols="12"
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'd-flex justify-end'
              : 'd-flex justify-center'
          "
        >
          <action-button
            v-if="tickets.selected"
            @click="addField"
            :disabled="
              !user.permissions.Event || !user.permissions.Event.update
            "
            iconLeft
            icon="mdi-plus"
          >
            Nuevo campo
          </action-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import qs from "query-string";
import { mapMutations, mapState, mapActions } from "vuex";
import ActionButton from "@/components/shared/ActionButton.vue";
import ModalTemplate from "@/components/shared/ModalTemplate";
import IconActionButton from "@/components/shared/IconActionButton.vue";
import Endpoints from "@/share/Endpoints";
import { formatExtraFields } from "@/utils";

export default {
  components: { ActionButton, ModalTemplate, IconActionButton },
  props: ["eventId"],
  data: () => ({
    tickets: {
      items: [],
      selected: null,
    },
    extraFields: [],
  }),
  created() {
    this.loading();
    this.getEventTickets();
    this.loaded();
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["realoadUserData"]),
    cancelEdition(field) {
      this.extraFields = this.extraFields.map((auxField) =>
        auxField._id === field._id
          ? { ...field.originalData, edit: false }
          : { ...auxField }
      );
    },

    //campo para imprimir los valores del endpoint
    // actionButonTest(){
    //   console.log(this.tickets)
    //   console.log(this.extraFields)
    // },
    minValueRule(value) {
      const isValid = value >= 1;
      return isValid || "El valor mínimo es 1";
    },

    uniqueOrderRule(value) {
      const isOrderUsed = this.extraFields.some(
        (field) => field.order === value && field !== this.currentEditingField
      );
      return !isOrderUsed || "Este número de orden ya está en uso";
    },

    cancelAction(field) {
      field?.edit && this.cancelEdition(field);
      field?.new && this.removeField(field._id);
    },
    formatTicketsData: ({ _id, name }) => ({ id: _id, name }),
    async getEventTickets() {
      const endpoint = qs.stringifyUrl({
        url: Endpoints.ticketsByEvent.replace(":eventId", this.eventId),
        query: {
          page: 1,
          limit: 100,
        },
      });
      const response = await this.axios.get(endpoint);
      if (response.status === 200) {
        const { tickets } = response.data;
        tickets.length > 0 &&
          (this.tickets.items = tickets.map(this.formatTicketsData));
      }
    },
    async handleTicketSelection(ticketId) {
      const response = await this.axios.get(
        Endpoints.extraFieldsTicket.replace(":ticketId", ticketId)
      );
      if (response.status === 200) {
        let fields = response.data.registrationFields;

        const fieldsWithOrder = fields.filter((field) => field.order != null);
        const fieldsWithoutOrder = fields.filter(
          (field) => field.order == null
        );

        fieldsWithOrder.sort((a, b) => a.order - b.order);

        let orderedFields = [...fieldsWithoutOrder];
        for (const field of fieldsWithOrder) {
          const position = field.order - 1;
          orderedFields.splice(position, 0, field);
        }

        this.extraFields = orderedFields.map((field) => ({
          ...field,
          edit: false,
        }));
      }
    },
    addField() {
      const field = {
        _id: new Date().getTime(),
        ticket: this.tickets.selected,
        event: this.eventId,
        fieldType: "TEXT",
        required: false,
        name: "",
        formattedName: "",
        description: "",
        options: [],
        new: true,
      };
      this.extraFields.push({ ...field, deleteDialog: false });
    },
    async createField(field) {
      try {
        this.loading();
        delete field.new;
        const { _id: temporaryId, ...restFieldProps } = field;
        const formattedName = formatExtraFields(field.name);
        const payload = {
          registrationField: { ...restFieldProps, formattedName },
        };
        const response = await this.axios.post(
          Endpoints.extraFieldsAddField,
          payload
        );
        if (response.status === 200) {
          const { registrationField } = response.data;
          this.extraFields = this.extraFields.map((ticket) =>
            ticket._id === temporaryId
              ? {
                  ...restFieldProps,
                  ...registrationField,
                  edit: false,
                  id: registrationField._id,
                }
              : ticket
          );
          this.show({
            color: "primary",
            text: "Campo extra creado",
          });
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.loaded();
      }
    },
    async updateField(field) {
      this.loading();
      try {
        const {
          name,
          description,
          required,
          event,
          status,
          fieldType,
          options,
          ticket,
          _id,
          order
        } = field;
        const registrationField = {
          name,
          event,
          status,
          formattedName: formatExtraFields(name),
          description,
          required,
          fieldType,
          options,
          ticket,
          order
        };
        const res = await this.axios.put(
          Endpoints.extraFieldsModifyField.replace(":fieldId", _id),
          { registrationField }
        );
        if (res.status === 200) {
          this.extraFields = this.extraFields.map((auxField) =>
            auxField._id === _id
              ? { ...auxField, ...registrationField, edit: false }
              : { ...auxField }
          );
          this.show({
            color: "primary",
            text: "Campo extra actualizado",
          });
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.loaded();
      }
    },
    saveField(field) {
      field?.edit && this.updateField(field);
      field?.new && this.createField(field);
    },
    editField(field) {
      field.edit = true;
      field.originalData = { ...field };
    },
    async deleteField(field) {
      const { _id: fieldId, new: newField } = field;
      if (!newField) {
        await this.axios.delete(
          Endpoints.extraFieldsModifyField.replace(":fieldId", fieldId)
        );
        this.show({
          color: "primary",
          text: "Campo eliminado correctamente",
        });
      }
      this.removeField(fieldId);
      field.deleteDialog = false;
    },
    removeField(fieldId) {
      this.extraFields = this.extraFields.filter(
        (field) => field._id !== fieldId
      );
    },
    disableField(field) {
      const { originalData, ...rest } = field;
      const isFormWithoutChanges =
        JSON.stringify(originalData) === JSON.stringify(rest);
      return isFormWithoutChanges;
    },
  },
};
</script>