<!--En este apartado se muestran los inscritos de un evento-->
<template>
  <div>
    <modal-template :open="isModalOpen" :primaryBtnProps="{
      show: true,
      text: 'Actualizar',
    }" :secondaryBtnProps="{
  show: true,
  text: 'Cancelar',
}" @close="closeModal" @continue="updateAttendee">
      <template #content>
        <p class="text-h4 text-center">Modificar asistente</p>
        <v-form ref="attendeesForm">
          <v-text-field v-model="attendee.name" label="Nombre" filled rounded />
          <v-text-field v-model="attendee.lastName" label="Apellidos" filled rounded />
          <v-text-field v-model="attendee.email" label="Email" filled rounded />
        </v-form>
      </template>
    </modal-template>
    <v-row class="ma-2 mt-5" align="center" justify="center" justify-md="space-around">
      <v-col cols="11" md="1" v-if="!invitationalEvent">
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="secondary" class="mr-5" fab small :disabled="(!user.permissions.reportsDownload ||
            !user.permissions.reportsDownload.update) &&
          user.role == 'ORGANIZATION_USER'
          " @click="fetchAttendeeReport">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn v-else color="secondary" class="mb-5" rounded block :disabled="(!user.permissions.reportsDownload ||
            !user.permissions.reportsDownload.update) &&
          user.role == 'ORGANIZATION_USER'
          " @click="fetchAttendeeReport">
          <span class="font-weight-bold">Descargar reporte</span>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="11" md="3">
        <v-dialog v-model="invitationDialog" width="80%" @click:outside="resetAtendeesSearch(), getAttendees()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="invitationalEvent" color="secondary" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-5'" rounded
              block v-bind="attrs" v-on="on">
              <span class="font-weight-bold">Gestionar Invitaciones</span>
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>

          <v-card color="#f4f4f4" v-if="invitationalEvent">
            <v-row class="ma-0 title-row" align="center">
              <v-card-title class="text-h4 font-weight-bold">
                Gestionar invitaciones
              </v-card-title>

              <v-spacer></v-spacer>

              <v-btn color="#1b273e" class="font-weight-bold white--text mr-5" rounded @click="handleSave()">
                <v-icon>mdi-save</v-icon>
                Guardar
              </v-btn>
            </v-row>

            <v-row class="ma-0">
              <v-col cols="5">
                <v-sheet outlined rounded="lg">
                  <v-row class="ma-4 mb-2 wc-h6"> Invitados </v-row>
                  <v-divider></v-divider>
                  <v-row justify="center" class="mx-4 my-1">
                    <v-sheet color="#1b273e" class="rounded-xl pl-3" style="flex-grow: 1">
                      <v-text-field v-model="search" @keypress.enter="searchAttendeesData()" background-color="wLightGray"
                        color="white" solo dense placeholder="Buscar invitado" hide-details="auto" class="rounded-search">
                        <template v-slot:prepend>
                          <v-btn color="#1b273e" icon small @click="searchAttendeesData()" :disabled="!user.permissions.Event ||
                            !user.permissions.Event.read
                            ">
                            <v-icon color="white">mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-sheet>
                  </v-row>
                  <v-row justify="center" class="mx-4 my-1">
                    <v-pagination v-model="searchData.page" :length="Math.ceil(totalAttendees / 10)" circle
                      :total-visible="7" @input="searchAttendeesData()"></v-pagination>
                  </v-row>
                  <v-row class="mx-4 my-0" align="center" v-for="attendee in attendees" :key="attendee._id">
                    <v-checkbox v-model="toRemove" :value="attendee.memberId"></v-checkbox>
                    <v-col cols="7" class="member-name">
                      <v-row>
                        <span class="member-name">
                          {{ attendee.name }} {{ attendee.firstLastname }}
                        </span>
                      </v-row>
                      <v-row>
                        <span class="caption"> {{ attendee.email }} </span>
                      </v-row>
                    </v-col>
                    <v-chip :color="homologateStatusWithColor(attendee.validationStatus)
                        .color
                      " class="rounded-lg font-weight-bold caption px-2 mx-auto" small>
                      {{
                        homologateStatusWithColor(attendee.validationStatus)
                          .status
                      }}
                    </v-chip>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col cols="2" class="center-col">
                <v-btn color="red" class="font-weight-black my-4" icon rounded x-large @click="handleRemove()">
                  <v-icon size="64">mdi-arrow-right</v-icon>
                </v-btn>

                <v-btn color="green" class="font-weight-black my-4" rounded icon x-large @click="handleInvite()">
                  <v-icon size="64">mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-sheet outlined rounded="lg">
                  <v-row class="ma-4 mb-2 wc-h6"> Miembros </v-row>
                  <v-divider></v-divider>
                  <v-row justify="center" class="mx-4 my-1">
                    <v-sheet color="#1b273e" class="rounded-xl pl-3" style="flex-grow: 1">
                      <v-text-field v-model="membersSearch" @keypress.enter="fetchMembers()" background-color="wLightGray"
                        color="white" solo dense placeholder="Buscar miembro" hide-details="auto" class="rounded-search">
                        <template v-slot:prepend>
                          <v-btn color="#1b273e" icon small @click="fetchMembers()" :disabled="!user.permissions.Event ||
                            !user.permissions.Event.read
                            ">
                            <v-icon color="white">mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-sheet>
                  </v-row>
                  <v-row justify="center" class="mx-4 my-1">
                    <v-pagination v-model="membersPage" :length="membersTotal" circle :total-visible="7"
                      @input="fetchMembers()"></v-pagination>
                  </v-row>
                  <v-row class="mx-4 my-0" align="center" v-for="member in members" :key="member._id">
                    <v-checkbox v-model="toInvite" :value="member._id" :disabled="isInvited(member._id)"></v-checkbox>
                    <v-col cols="7" class="member-name">
                      <v-row>
                        <span class="member-name">
                          {{ member.name }} {{ member.firstLastname }}
                        </span>
                      </v-row>
                      <v-row>
                        <span class="caption"> {{ member.email }} </span>
                      </v-row>
                    </v-col>
                    <v-chip :color="homologateStatusWithColor(member.validationStatus).color
                      " class="rounded-lg font-weight-bold caption px-2 mx-auto" small>
                      {{
                        homologateStatusWithColor(member.validationStatus)
                          .status
                      }}
                    </v-chip>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="11" md="3">
        <v-btn color="amber darken-1" class="font-weight-bold" rounded block :disabled="diplomasSent ||
          !user.permissions.Event ||
          !user.permissions.Event.update ||
          invitationalEvent
          " @click="dialog = true">
          <v-icon>mdi-send</v-icon>
          Enviar Constancias
        </v-btn>
      </v-col>
      <v-col cols="11" md="6">
        <v-sheet color="accent" class="rounded-xl pl-3" style="flex-grow: 1; max-width: 600px">
          <v-text-field v-model="search" @keypress.enter="searchAttendeesData()" background-color="wLightGray"
            color="white" solo dense placeholder="Buscar asistente" hide-details="auto" class="rounded-search">
            <template v-slot:prepend>
              <v-btn color="accent" icon small @click="searchAttendeesData()" :disabled="!user.permissions.Event || !user.permissions.Event.read
                ">
                <v-icon color="white">mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="cols" :items="attendees" :options.sync="options" :loading="loading"
          loading-text="Cargando asistentes..." no-results-text="Cargando asistentes..." no-data-text="No hay asistentes"
          :server-items-length="totalAttendees" :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageOptions: [5, 10, 20, 50],
          }" class="elevation-1" disable-sort @pagination="searchAttendeesData()">
          <template v-slot:[`item.actions`]="{ item }">
            <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
              {{ item.value }}
            </div>
            <div :class="$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : ''
              ">
              <icon-button color="blue darken-1" :disabled="!user.permissions.Event ||
                !user.permissions.Event.update ||
                invitationalEvent
                " @click="sendTicket(item._id)" tooltip="Enviar Ticket">
                mdi-ticket-confirmation-outline
              </icon-button>
              <icon-button color="accent" :disabled="!user.permissions.Event || !user.permissions.Event.update
                " @click="updateAttendeeDialog(item)" tooltip="Actualizar Asistente">
                mdi-pencil-outline
              </icon-button>
              <!-- <icon-button
                      color="blue"
                      @click="downloadDiploma(item.diplomaUrl)"
                      tooltip="Descargar Diploma"
                      :disabled="
                        !item.diplomaUrl ||
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      mdi-download
                    </icon-button> -->
              <AttendeeDocs :item="item" />
              <AttendeeDocs :item="item" :documentFields="true" />
            </div>
          </template>
          <template v-slot:[`item.checkIn`]="{ item }">
            <div class="d-flex justify-center" :class="$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : ''
              ">
              <v-switch v-model="item.checkIn" color="accent" @change="handleCheckIn(item)" :disabled="!user.permissions.Event || !user.permissions.Event.update
                " />
            </div>
          </template>
          <template v-slot:[`item.needsDiploma`]="{ item }">
            <div class="d-flex justify-center" :class="$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : ''
              ">
              <v-switch v-model="item.needsDiploma" color="accent" :disabled="!user.permissions.Event || !user.permissions.Event.update
                " @change="handleDiplomaValidation(item)" />
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5"> Atención </v-card-title>
          <v-card-text>Estás a punto de hacer un envío de constancias a tu lista de
            asistentes. Antes de avanzar ten en cuenta:
            <ul class="pt-5">
              <li>Solo cuentas con un envío de constacias por evento.</li>
              <li class="pt-5">
                Puedes prender o apagar el switch si hay asistentes que no
                deseas que reciban su contancia.
              </li>
              <li class="pt-5">
                Recuerda revisar que todos tus accesos cuenten con una
                constancia previamente cargada.
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialog = false"> Cancelar </v-btn>
            <v-btn color="primary" text @click="handleSendDiplomas">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import qs from "query-string";
import { parse } from "json2csv";
import { downloadFile } from "@/utils";
import { mapMutations, mapState, mapActions } from "vuex";

import ModalTemplate from "@/components/shared/ModalTemplate";
import AttendeeDocs from "@/components/dashboard/event/AttendeeDocs.vue";
import ActionButton from "@/components/shared/ActionButton";
import IconButton from "@/components/shared/IconButton";
import Endpoints from "@/share/Endpoints";
import InvitationDialog from "@/components/dashboard/event/InvitationDialog.vue";
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";
import * as XLSX from "xlsx/xlsx.mjs";
import Vue from "vue";

export default {
  components: {
    ModalTemplate,
    ActionButton,
    IconButton,
    AttendeeDocs,
    InvitationDialog,
  },
  props: ["eventId"],
  data() {
    return {
      diplomasSent: false,
      invitationalEvent: false,
      attendees: [{}],
      attendee: {
        name: "",
        lastName: "",
        email: "",
      },
      toInvite: [],
      toRemove: [],
      invitedMembers: [],
      removedMembers: [],
      invitationDialog: false,
      search: "",
      loading: true,
      isModalOpen: false,
      //Para paginado
      options: {},
      searchData: {
        page: 1,
        itemsPerPage: 10,
        gte: null,
        lte: null,
      },
      members: [],
      membersPage: 1,
      membersSearch: "",
      membersTotal: 0,
      totalAttendees: 0,
      cols: [
        {
          text: "Nombre",
          align: "center",
          value: "name",
        },
        {
          text: "Apellido",
          align: "center",
          value: "lastName",
        },
        {
          text: "Email",
          align: "center",
          value: "email",
        },
        {
          text: "Tipo de Boleto",
          align: "center",
          value: "ticketName",
        },
        {
          text: "Asistencia",
          align: "center",
          value: "checkIn",
        },
        {
          text: "Constancia",
          align: "center",
          value: "needsDiploma",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
        },
      ],
      dialog: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("event", ["eventSelected"]),
  },
  async created() {
    await this.fetchEventById(this.eventId);
    await this.fetchInvitationalEvent();
    await this.getAttendees();
    await this.fetchMembers();
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("event", ["fetchEventById", "updateEvent"]),
    homologateStatusWithColor,
    async handleSave() {
      try {
        const response = await this.axios({
          method: "POST",
          url: `/events/inviteMembers/${this.eventId}`,
          data: {
            members: this.invitedMembers,
          },
        });

        const removedResponse = await this.axios({
          method: "POST",
          url: `/events/removeInvitedMembers/${this.eventId}`,
          data: {
            members: this.removedMembers,
          },
        });

        if (response.status === 200 && removedResponse.status === 200) {
          this.show({
            color: "primary",
            text: "¡Invitaciones guardadas correctamente!",
          });
        } else {
          this.show({
            color: "error",
            text: "¡Error al realizar las invitaciones!",
          });
        }
      } catch (error) {
        this.show({
          color: "error",
          text: "¡Error al guardar!",
        });
      } finally {
        this.invitationDialog = false;
        this.resetAtendeesSearch();
        await this.getAttendees();
      }
    },
    handleInvite() {
      for (let member of this.members) {
        if (this.toInvite.includes(member._id)) {
          this.attendees.push(member);
          this.invitedMembers.push(member._id);
          this.removedMembers = this.removedMembers.filter(
            (id) => id !== member._id
          );
        }
      }
      this.toInvite = [];
    },
    handleRemove() {
      for (let attendee of this.attendees) {
        if (this.toRemove.includes(attendee.memberId)) {
          this.attendees = this.attendees.filter(
            (attendee) => !this.toRemove.includes(attendee.memberId)
          );
          this.removedMembers.push(attendee.memberId);
          this.invitedMembers = this.invitedMembers.filter(
            (id) => id !== attendee.memberId
          );
        }
      }
      this.toRemove = [];
    },
    isInvited(id) {
      return this.attendees.some((attendee) => attendee.memberId === id);
    },
    async fetchInvitedMembers() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `/members/invited/${this.eventId}`,
        });
        if (response.status === 200) {
          this.members = response.data.members;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMembers() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `/members/invitation?page=${this.membersPage}&search=${this.membersSearch}`,
        });
        if (response.status === 200) {
          this.members = response.data.members;
          this.membersTotal = response.data.totalPages;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchInvitationalEvent() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `/events/invitationalEvent/${this.eventId}`,
        });
        if (response.status === 200) {
          this.invitationalEvent = response.data.invitationalEvent;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchAttendeesData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.searchData.page = page;
      this.searchData.itemsPerPage = itemsPerPage;

      await this.getAttendees();
      /*
      this.loading = true;
      const endpoint = qs.stringifyUrl({
        url: Endpoints.attendees.replace(/:id/, this.eventId),
        query: {
          name: this.search,
          limit: this.searchData.itemsPerPage,
          page: this.searchData.page,
        },
      });
      const response = await this.axios.get(endpoint);

      if (response.status === 200) {
        console.log(response.data);
        const { attendees, event, totalDocs } = response.data;
        this.totalAttendees = totalDocs;
        this.attendees = attendees.map((attendee) => ({
          id: attendee._id,
          infoId: attendee.attendeeInformation._id,
          name: attendee.attendeeInformation.name,
          lastName: attendee.attendeeInformation.lastName,
          email: attendee.attendeeInformation.email,
          checkIn: attendee.attendeeInformation.checkIn,
          needsDiploma: !!attendee.attendeeInformation.needsDiploma,
          diplomaUrl: attendee.attendeeInformation.diplomaUrl,
        }));
        this.diplomasSent = event.diplomasSended;
      }
      this.loading = false;*/
    },
    async getAttendees() {
      this.loading = true;
      const endpoint = qs.stringifyUrl({
        url: Endpoints.attendees.replace(/:id/, this.eventId),
        query: {
          name: this.search,
          limit: this.searchData.itemsPerPage,
          page: this.searchData.page,
        },
      });
      const response = await this.axios.get(endpoint);

      if (response.status === 200) {
        console.log(response.data);
        const { attendees, event, totalDocs } = response.data;
        this.totalAttendees = totalDocs;
        this.attendees = attendees.map((attendee) => ({
          _id: attendee._id,
          infoId: attendee.attendeeInformation._id,
          name: attendee.attendeeInformation.name,
          lastName: attendee.attendeeInformation.lastName,
          email: attendee.attendeeInformation.email,
          checkIn: attendee.attendeeInformation.checkIn,
          needsDiploma: !!attendee.attendeeInformation.needsDiploma,
          diplomaUrl: attendee.attendeeInformation.diplomaUrl,
          attendeeInfo: attendee.attendeeInformation,
          memberId: attendee.member ? attendee.member._id : null,
          validationStatus: attendee.member
            ? attendee.member.validationStatus
            : null,
          firstLastname: attendee.member ? attendee.member.firstLastname : null,
          ticketName: attendee.ticket ? attendee.ticket.name : attendee.attendeeInformation.ticketName
        }));
        this.diplomasSent = event.diplomasSended;
      }
      this.loading = false;
    },
    async updateAttendee() {
      const { _id, name, lastName, email } = this.attendee;
      const payload = {
        attendee: {
          name,
          lastName,
          email,
          event: this.eventId,
        },
      };
      const response = await this.axios.put(
        Endpoints.attendees.replace(/:id/, _id),
        payload
      );

      if (response.status === 200) {
        this.attendees = this.attendees.map((attendee) =>
          attendee._id === this.attendee._id
            ? { ...attendee, ...this.attendee }
            : attendee
        );

        this.closeModal();
        this.show({
          color: "primary",
          text: "¡Asistente actualizado correctamente!",
        });
      }
    },
    async sendTicket(id) {
      const payload = {
        attendee: {
          id,
          event: this.eventId,
        },
      };
      await this.axios.post(Endpoints.attendeesSendTicket, payload);

      this.show({
        color: "primary",
        text: "¡El ticket ha sido enviado!",
      });
    },
    async handleCheckIn(item) {
      const payload = {
        attendee: {
          id: item._id,
          event: this.eventId,
          checkIn: item.checkIn,
        },
      };
      await this.axios.post(Endpoints.attendeesCheckIn, payload);
    },
    async handleSendDiplomas() {
      this.loading = true;

      await this.axios.post(
        Endpoints.eventsSendDiplomas.replace(/:eventId/, this.eventId)
      );

      this.dialog = false;

      this.show({
        color: "primary",
        text: "¡Los diplomas se han enviado correctamente!",
      });

      this.loading = false;
    },
    async fetchAttendeeReport() {
      this.loading = true;

      const endpoint = qs.stringifyUrl({
        url: Endpoints.attendeesReport.replace(/:id/, this.eventId),
      });

      const response = await this.axios.get(endpoint);

      if (response.status === 200) {
        const { attendees } = response.data;

        const ws = XLSX.utils.json_to_sheet(attendees);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "attendees");
        XLSX.writeFile(wb, "attendees.xlsx");
      }

      this.loading = false;
    },
    async handleDiplomaValidation(item) {
      try {
        const { _id, name, lastName, email, needsDiploma } = item;
        const payload = {
          attendee: {
            name,
            lastName,
            email,
            needsDiploma,
            event: this.eventId,
          },
        };

        await this.axios.put(Endpoints.attendees.replace(/:id/, _id), payload);
      } catch (error) {
        console.log(error);
      }
    },
    downloadDiploma(url) {
      const urlParts = url.split("/");
      const link = document.createElement("a");
      link.href = url;
      link.download = urlParts[urlParts.length - 1];
      link.click();
      link.remove();
    },
    updateAttendeeDialog(attendee) {
      this.isModalOpen = true;
      this.attendee = { ...attendee };
    },
    closeModal() {
      this.isModalOpen = false;
      this.$refs.attendeesForm.reset();
    },
    getObjectKeys(array) {
      let keys = [];

      for (let i = 0; i < array.length; i++) {
        Object.keys(array[i]).forEach(function (key) {
          if (keys.indexOf(key) == -1) {
            keys.push(key);
          }
        });
      }

      return keys;
    },
    resetAtendeesSearch() {
      this.options.page = 1;
      this.search = "";
    },
  },
};
</script>

<style scoped>
.member-name {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(90vh - 100px);
}

.title-row {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  background-color: #fafafa !important;
}
</style>
